import {
  Component,
  OnInit,
  Input,
  LOCALE_ID,
  Output,
  EventEmitter,
} from "@angular/core";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import * as moment from "src/assets/plugins/moment/moment";
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localeEs, "es");

@Component({
  selector: "app-cronograma-fechas",
  templateUrl: "./cronograma-fechas.component.html",
  styleUrls: ["./cronograma-fechas.component.css"],
  providers: [{ provide: LOCALE_ID, useValue: "es" }],
})
export class CronogramaFechasComponent implements OnInit {
  @Input() idLicitacion: number;
  @Input() idEtapa: number;
  @Input() fecha_inicio: string;
  @Output() data: EventEmitter<any[]> = new EventEmitter<any[]>();
  etapas: any[] = [];
  fechaCronogramaLic: any[] = [];
  minDate: string;
  // variables para los inputs de fechas
  hInicioTemp: any[] = [];
  fInicioTemp: any[] = [];
  hTerminoTemp: any[] = [];
  fTerminoTemp: any[] = [];
  fInicioText: any[] = [];
  fTerminoText: any[] = [];
  // variables para llenar en caso de que exista la licitacion
  cronograma: any[];
  versionCronograma: number;
  userId: number;
  ordenEtapaActual: number;
  constructor(
    private licitacionService: LicitacionService,
    private etapaService: EtapasService,
    private notifyService: NotificationService
  ) {
    this.versionCronograma = 0;
    this.ordenEtapaActual = 0;
    this.userId = JSON.parse(localStorage.getItem("userData")).userId;
  }

  ngOnInit(): void {
    // trtaer las fechas del cronograma en caso de que existan
    if (this.idLicitacion) {
      this.licitacionService
        .getCronogramaLicitacionId(this.idLicitacion)
        .subscribe(
          (resp) => {
            this.cronograma = JSON.parse(resp).sort(
              (a, b) => a.ORDEN - b.ORDEN
            );
            this.data.emit(this.cronograma);

            this.cronograma.forEach((element) => {
              const el = JSON.parse(JSON.stringify(element));
              if (el.FECHA_INICIO_ETAPA != null) {
                this.fInicioTemp[el.ETAPA_CORRELATIVO] = new Date(
                  el.FECHA_INICIO_ETAPA
                )
                  .toISOString()
                  .split("T")[0];
              } else {
                this.fInicioTemp[el.ETAPA_CORRELATIVO] = el.FECHA_INICIO_ETAPA;
              }
              if (el.FECHA_TERMINO_ETAPA != null) {
                this.fTerminoTemp[el.ETAPA_CORRELATIVO] = new Date(
                  el.FECHA_TERMINO_ETAPA
                )
                  .toISOString()
                  .split("T")[0];
              } else {
                this.fTerminoTemp[el.ETAPA_CORRELATIVO] =
                  el.FECHA_TERMINO_ETAPA;
              }
            });

            this.ordenEtapaActual = this.cronograma.filter(
              (x) => x.ETAPA_CORRELATIVO == this.idEtapa
            )[0]?.ORDEN;
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Obtener cronograma licitación"
            );
          }
        );
    } else {
      this.etapaService.getEtapas().subscribe(
        (resp) => {
          this.etapas = JSON.parse(resp).sort((a, b) => a.ORDEN - b.ORDEN);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener etapas del Cronograma."
          );
        }
      );
    }

    if (this.fecha_inicio) {
      this.minDate = this.fecha_inicio;
      const dateTemp = new Date(this.fecha_inicio);
      const date15 = dateTemp.setDate(dateTemp.getDate() + 7);

      this.minDate = moment(new Date(date15)).format("YYYY-MM-DD");
      //new Date(fechaInicioSelect.setDate(fechaInicioSelect.getDate() + this.etapas[etapaIndex].DIAS_ESTIMADO))
    } else {
      this.minDate = moment(new Date()).format("YYYY-MM-DD");
    }

    this.getVersionCronograma();
  }

  getVersionCronograma() {
    this.licitacionService
      .getUltimaverisonCronograma(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.versionCronograma =
            this.idEtapa === 0 ? 1 : JSON.parse(resp)[0].version;
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Error al obtener la última versión del cronograma"
          );
        }
      );
  }

  onChengeObligatorio(correlativo: number, obligatorio: boolean) {
    const postData = {
      in_licitacion: this.idLicitacion,
      pin_correlativo: correlativo,
      in_obligatorio: obligatorio === true ? 1 : 0,
    };

    if (this.idEtapa === 0) {
      this.licitacionService.putEtapaObligatoriaCronograma(postData).subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Cronograma actualizado exitosamente",
            "Cronograma"
          );
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Cronograma");
        }
      );
    } else {
      this.licitacionService
        .postRegistraHistoricoCronogramaLicitacion({
          in_correlativo_licitacion: parseInt(this.idLicitacion.toString()),
          in_usuario_correlativo: this.userId,
          in_etapa_cambio_historico: parseInt(this.idEtapa.toString()),
        })
        .subscribe(
          (resp) => {
            this.licitacionService
              .putEtapaObligatoriaCronograma(postData)
              .subscribe(
                (resp) => {
                  this.licitacionService
                    .putActualizaVersionCronograma({
                      pin_lic_correlativo: parseInt(
                        this.idLicitacion.toString()
                      ),
                      in_version: this.versionCronograma + 1,
                    })
                    .subscribe((resp) => {
                      this.getVersionCronograma();
                    });

                  this.notifyService.showSuccess(
                    "Cronograma actualizado exitosamente",
                    "Cronograma"
                  );
                },
                (err) => {
                  this.notifyService.showError(err.error.message, "Cronograma");
                }
              );
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Registro de historial de cronograma"
            );
          }
        );
    }
  }

  getCronograma() {
    this.licitacionService
      .getCronogramaLicitacionId(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.cronograma = JSON.parse(resp).sort((a, b) => a.ORDEN - b.ORDEN);
          this.data.emit(this.cronograma);
          this.cronograma.forEach((element) => {
            const el = JSON.parse(JSON.stringify(element));
            if (el.FECHA_INICIO_ETAPA != null) {
              this.fInicioTemp[el.ETAPA_CORRELATIVO] = new Date(
                el.FECHA_INICIO_ETAPA
              )
                .toISOString()
                .split("T")[0];
            } else {
              this.fInicioTemp[el.ETAPA_CORRELATIVO] = el.FECHA_INICIO_ETAPA;
            }
            if (el.FECHA_TERMINO_ETAPA != null) {
              this.fTerminoTemp[el.ETAPA_CORRELATIVO] = new Date(
                el.FECHA_TERMINO_ETAPA
              )
                .toISOString()
                .split("T")[0];
            } else {
              this.fTerminoTemp[el.ETAPA_CORRELATIVO] = el.FECHA_TERMINO_ETAPA;
            }
          });
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cronograma licitación"
          );
        }
      );
  }

  async onUpdateLicitacionFechas() {
    for (let i = 0; i < this.fechaCronogramaLic?.length; i++) {
      const elem = this.fechaCronogramaLic[i];
      const putData: {
        in_licitacion: number;
        in_etapa_correlativo: number;
        in_fecha_inicio: string;
        in_fecha_termino: string;
        in_hora: string;
        iv_usuario_creacion: number;
        in_version: number;
      } = {
        in_licitacion: this.idLicitacion,
        in_etapa_correlativo: elem.in_etapa,
        in_fecha_inicio: elem.in_fecha_ini,
        in_fecha_termino: elem.in_fecha_fin,
        in_hora: elem.in_hora,
        iv_usuario_creacion: 0,
        in_version: this.idEtapa === 0 ? 1 : this.versionCronograma + 1,
      };
      this.licitacionService.putFechaCronogramaLic(putData).subscribe(
        (resp) => {},
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Actualización de cronograma"
          );
        }
      );
    }
  }

  async onSaveLicitacionFechasOriginales() {
    for (let i = 0; i < this.fechaCronogramaLic?.length; i++) {
      const elem = this.fechaCronogramaLic[i];
      const putData: {
        in_licitacion: number;
        in_etapa_correlativo: number;
        in_fecha_inicio: string;
        in_fecha_termino: string;
        in_hora: string;
        iv_usuario_creacion: number;
      } = {
        in_licitacion: this.idLicitacion,
        in_etapa_correlativo: elem.in_etapa,
        in_fecha_inicio: elem.in_fecha_ini,
        in_fecha_termino: elem.in_fecha_fin,
        in_hora: elem.in_hora,
        iv_usuario_creacion: 0,
      };
      this.licitacionService.putFechaCronogramaLicOriginal(putData).subscribe(
        (resp) => {},
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Actualización de cronograma"
          );
        }
      );
    }
  }

  onChangeProceso(etapa_correlativo: number, activo: any) {
    if (etapa_correlativo == this.idEtapa) {
      this.notifyService.showWarning(
        "No se puede desactivar la etapa seleccionada ya que es una etapa en curso.",
        "Cambio de cronograma"
      );
      return;
    }

    const putData: {
      in_licitacion: number;
      in_etapa_correl: number;
      in_usuario: string;
      in_activo: string;
    } = { in_licitacion: 0, in_etapa_correl: 0, in_usuario: "", in_activo: "" };
    if (etapa_correlativo && this.idLicitacion) {
      putData.in_licitacion = this.idLicitacion;
      putData.in_etapa_correl = etapa_correlativo;
      putData.in_activo = activo === true ? "S" : "N";
      this.licitacionService.putCronogramaLic(putData).subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Actualización exitosa",
            "Cambio de cronograma"
          );

          if (this.idEtapa === 0) {
            this.licitacionService.putCronogramaLicOriginal(putData).subscribe(
              (resp) => {
                console.log("Cronograma original actualizado.");
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Cambio de cronograma"
                );
              }
            );
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Cambio de cronograma"
          );
        }
      );
    }
  }

  onChangeProcesoEdit(etapa_correlativo: number, activo: any) {
    if (etapa_correlativo == this.idEtapa) {
      this.notifyService.showWarning(
        "No se puede desactivar la etapa seleccionada ya que es una etapa en curso.",
        "Cambio de cronograma"
      );
      return;
    }

    const putData: {
      in_licitacion: number;
      in_etapa_correl: number;
      in_usuario: string;
      in_activo: string;
    } = { in_licitacion: 0, in_etapa_correl: 0, in_usuario: "", in_activo: "" };
    if (etapa_correlativo && this.idLicitacion) {
      putData.in_licitacion = this.idLicitacion;
      putData.in_etapa_correl = etapa_correlativo;
      putData.in_activo = activo === true ? "S" : "N";
      this.licitacionService.putCronogramaLic(putData).subscribe(
        (resp) => {
          if (this.idEtapa === 0) {
            this.licitacionService.putCronogramaLicOriginal(putData).subscribe(
              (resp) => {
                console.log("Cronograma original actualizado.");

                this.licitacionService
                  .getCronogramaLicitacionId(this.idLicitacion)
                  .subscribe(
                    (resp) => {
                      this.cronograma = JSON.parse(resp).sort(
                        (a, b) => a.ORDEN - b.ORDEN
                      );
                      this.cronograma.forEach((element) => {
                        const el = JSON.parse(JSON.stringify(element));
                        if (el.FECHA_INICIO_ETAPA != null) {
                          this.fInicioTemp[el.ETAPA_CORRELATIVO] = new Date(
                            el.FECHA_INICIO_ETAPA
                          )
                            .toISOString()
                            .split("T")[0];
                        } else {
                          this.fInicioTemp[el.ETAPA_CORRELATIVO] =
                            el.FECHA_INICIO_ETAPA;
                        }
                        if (el.FECHA_TERMINO_ETAPA != null) {
                          this.fTerminoTemp[el.ETAPA_CORRELATIVO] = new Date(
                            el.FECHA_TERMINO_ETAPA
                          )
                            .toISOString()
                            .split("T")[0];
                        } else {
                          this.fTerminoTemp[el.ETAPA_CORRELATIVO] =
                            el.FECHA_TERMINO_ETAPA;
                        }
                      });

                      this.notifyService.showSuccess(
                        "Actualización",
                        "Cambio de cronograma"
                      );
                    },
                    (err) => {
                      this.notifyService.showError(
                        err.error.message,
                        "Obtener cronograma licitación"
                      );
                    }
                  );
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Cambio de cronograma"
                );
              }
            );
          } else {
            this.licitacionService
              .postRegistraHistoricoCronogramaLicitacion({
                in_correlativo_licitacion: parseInt(
                  this.idLicitacion.toString()
                ),
                in_usuario_correlativo: this.userId,
                in_etapa_cambio_historico: parseInt(this.idEtapa.toString()),
              })
              .subscribe(
                (resp) => {
                  this.licitacionService
                    .getCronogramaLicitacionId(this.idLicitacion)
                    .subscribe(
                      (resp) => {
                        this.cronograma = JSON.parse(resp).sort(
                          (a, b) => a.ORDEN - b.ORDEN
                        );

                        this.licitacionService
                          .putActualizaVersionCronograma({
                            pin_lic_correlativo: parseInt(
                              this.idLicitacion.toString()
                            ),
                            in_version: this.versionCronograma + 1,
                          })
                          .subscribe((resp) => {
                            this.cronograma.forEach((element) => {
                              const el = JSON.parse(JSON.stringify(element));
                              if (el.FECHA_INICIO_ETAPA != null) {
                                this.fInicioTemp[el.ETAPA_CORRELATIVO] =
                                  new Date(el.FECHA_INICIO_ETAPA)
                                    .toISOString()
                                    .split("T")[0];
                              } else {
                                this.fInicioTemp[el.ETAPA_CORRELATIVO] =
                                  el.FECHA_INICIO_ETAPA;
                              }
                              if (el.FECHA_TERMINO_ETAPA != null) {
                                this.fTerminoTemp[el.ETAPA_CORRELATIVO] =
                                  new Date(el.FECHA_TERMINO_ETAPA)
                                    .toISOString()
                                    .split("T")[0];
                              } else {
                                this.fTerminoTemp[el.ETAPA_CORRELATIVO] =
                                  el.FECHA_TERMINO_ETAPA;
                              }
                            });

                            this.getVersionCronograma();
                          });

                        this.notifyService.showSuccess(
                          "Actualización exitosa",
                          "Cambio de cronograma"
                        );
                      },
                      (err) => {
                        this.notifyService.showError(
                          err.error.message,
                          "Obtener cronograma licitación"
                        );
                      }
                    );
                },
                (err) => {
                  this.notifyService.showError(
                    err.error.message,
                    "Registro de historial de cronograma"
                  );
                }
              );
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Cambio de cronograma"
          );
        }
      );
    }
  }

  calcularDiasHabiles(date: Date) {
    let fechaSelected = date;

    if (fechaSelected.getDay() === 6) {
      fechaSelected = new Date(
        fechaSelected.setDate(fechaSelected.getDate() + 2)
      );
    } else if (fechaSelected.getDay() === 0) {
      fechaSelected = new Date(
        fechaSelected.setDate(fechaSelected.getDate() + 1)
      );
    }

    return fechaSelected;
  }

  /** Cambio en forma de ingreso fechas del formulario de creacion de licitacion */
  onFechaInicio(idEtapa: number, fecha: string, etapaIndex: number) {
    let fechaSplit = fecha.split("-");
    let fechaActual = Date.UTC(
      +fechaSplit[0],
      +fechaSplit[1] - 1,
      +fechaSplit[2],
      12,
      0,
      0
    );
    var fechaInicioSelect = new Date(fechaActual);

    fechaInicioSelect = this.calcularDiasHabiles(fechaInicioSelect);

    var fechaTerminoSelect = new Date(
      fechaInicioSelect.setDate(
        fechaInicioSelect.getDate() + this.etapas[etapaIndex].DIAS_ESTIMADO
      )
    );

    fechaTerminoSelect = this.calcularDiasHabiles(fechaTerminoSelect);

    if (this.etapas[etapaIndex].DIAS_ESTIMADO === 0) {
      fechaTerminoSelect = fechaInicioSelect;
    }

    let fechaM = fechaTerminoSelect;

    this.fInicioTemp[idEtapa] = moment(
      this.calcularDiasHabiles(new Date(fechaActual))
    ).format("YYYY-MM-DD");
    var fechaTerminoTemp = new Date(fechaTerminoSelect);
    this.fTerminoTemp[idEtapa] = moment(fechaTerminoTemp).format("YYYY-MM-DD");

    this.fechaCronogramaLic.push({
      in_etapa: idEtapa,
      in_fecha_ini: this.fInicioTemp[idEtapa],
      in_fecha_fin: this.fTerminoTemp[idEtapa],
      in_hora: 1,
    });

    this.calcularFechas(etapaIndex, moment(fechaM).format("YYYY-MM-DD"));
  }

  onFechaInicioEdit(idEtapa: number, fecha: string, etapaIndex: number) {
    let fechaSplit = fecha.split("-");
    let fechaActual = Date.UTC(
      +fechaSplit[0],
      +fechaSplit[1] - 1,
      +fechaSplit[2],
      12,
      0,
      0
    );
    let fechaInicioSelect = new Date(fechaActual);

    fechaInicioSelect = this.calcularDiasHabiles(fechaInicioSelect);

    let fechaTerminoSelect = new Date(
      fechaInicioSelect.setDate(
        fechaInicioSelect.getDate() + this.cronograma[etapaIndex].DIAS_ESTIMADO
      )
    );

    fechaTerminoSelect = this.calcularDiasHabiles(fechaTerminoSelect);

    if (this.cronograma[etapaIndex].DIAS_ESTIMADO == 0) {
      fechaTerminoSelect = fechaInicioSelect;
    }

    let fechaM = fechaTerminoSelect;

    this.fInicioTemp[idEtapa] = moment(
      this.calcularDiasHabiles(new Date(fechaActual))
    ).format("YYYY-MM-DD");
    //var fechaTerminoTemp = new Date(fechaTerminoSelect);
    this.fTerminoTemp[idEtapa] =
      moment(fechaTerminoSelect).format("YYYY-MM-DD");

    this.fechaCronogramaLic.push({
      in_etapa: idEtapa,
      in_fecha_ini: this.fInicioTemp[idEtapa],
      in_fecha_fin: this.fTerminoTemp[idEtapa],
      in_hora: 1,
    });

    this.calcularFechasEdit(etapaIndex, moment(fechaM).format("YYYY-MM-DD"));
  }

  onFechaTermino(idEtapa: number, fecha: string, etapaIndex: number) {
    let fechaSplit = fecha.split("-");
    let fechaActual = Date.UTC(
      +fechaSplit[0],
      +fechaSplit[1] - 1,
      +fechaSplit[2],
      12,
      0,
      0
    );
    let fechaSelected = new Date(fechaActual);

    fechaSelected = this.calcularDiasHabiles(fechaSelected);

    this.fTerminoTemp[idEtapa] = moment(fechaSelected).format("YYYY-MM-DD");
    //let fechaM = new Date(fecha);

    this.fTerminoTemp[idEtapa] = moment(fechaSelected).format("YYYY-MM-DD");
    this.fInicioTemp[idEtapa + 1] = moment(fechaSelected).format("YYYY-MM-DD");
    this.fechaCronogramaLic.push({
      in_etapa: idEtapa,
      in_fecha_ini: this.fInicioTemp[idEtapa],
      in_fecha_fin: this.fTerminoTemp[idEtapa],
      in_hora: 1,
    });

    this.calcularFechas(etapaIndex, fecha);
  }

  onFechaTerminoEdit(idEtapa: number, fecha: string, etapaIndex: number) {
    let fechaSplit = fecha.split("-");
    let fechaActual = Date.UTC(
      +fechaSplit[0],
      +fechaSplit[1] - 1,
      +fechaSplit[2],
      12,
      0,
      0
    );
    let fechaSelected = new Date(fechaActual);

    fechaSelected = this.calcularDiasHabiles(fechaSelected);

    this.fTerminoTemp[idEtapa] = moment(fechaSelected).format("YYYY-MM-DD");
    //let fechaM = new Date(fecha);

    this.fTerminoTemp[idEtapa] = moment(fechaSelected).format("YYYY-MM-DD");
    this.fInicioTemp[idEtapa + 1] = moment(fechaSelected).format("YYYY-MM-DD");
    this.fechaCronogramaLic.push({
      in_etapa: idEtapa,
      in_fecha_ini: this.fInicioTemp[idEtapa],
      in_fecha_fin: this.fTerminoTemp[idEtapa],
      in_hora: 1,
    });

    this.calcularFechasEdit(etapaIndex, fecha);
  }

  calcularFechas(etapaIndex: number, fechaM: string) {
    let fechaSplit = fechaM.split("-");
    let prueba = +fechaSplit[2] + 1;
    let fechaActual = Date.UTC(
      +fechaSplit[0],
      +fechaSplit[1] - 1,
      prueba,
      12,
      0,
      0
    );
    let fechaFinAnterior = new Date(fechaActual);

    for (let index = etapaIndex + 1; index <= this.etapas.length - 1; index++) {
      const element = this.etapas[index];

      fechaFinAnterior = this.calcularDiasHabiles(fechaFinAnterior);
      this.fInicioTemp[element.CORRELATIVO] =
        moment(fechaFinAnterior).format("YYYY-MM-DD");
      var fechaTerminoTemp = new Date(
        fechaFinAnterior.setDate(
          fechaFinAnterior.getDate() + element.DIAS_ESTIMADO
        )
      );

      if (this.etapas[etapaIndex].DIAS_ESTIMADO === 0) {
        fechaTerminoTemp = new Date(fechaFinAnterior);
      }

      fechaTerminoTemp = this.calcularDiasHabiles(fechaTerminoTemp);

      this.fTerminoTemp[element.CORRELATIVO] =
        moment(fechaTerminoTemp).format("YYYY-MM-DD");

      this.fechaCronogramaLic.push({
        in_etapa: element.CORRELATIVO,
        in_fecha_ini: this.fInicioTemp[element.CORRELATIVO],
        in_fecha_fin: this.fTerminoTemp[element.CORRELATIVO],
        in_hora: 1,
      });

      fechaFinAnterior = new Date(
        fechaTerminoTemp.setDate(fechaTerminoTemp.getDate() + 1)
      );
    }

    if (this.idEtapa !== 0) {
      this.licitacionService
        .postRegistraHistoricoCronogramaLicitacion({
          in_correlativo_licitacion: parseInt(this.idLicitacion.toString()),
          in_usuario_correlativo: this.userId,
          in_etapa_cambio_historico: parseInt(this.idEtapa.toString()),
        })
        .subscribe(
          (resp) => {
            this.onUpdateLicitacionFechas().then(async () => {
              if (this.idEtapa === 0) {
                this.onSaveLicitacionFechasOriginales().then(() => {
                  this.fechaCronogramaLic = [];
                  this.getCronograma();
                });
              } else {
                this.licitacionService
                  .putActualizaVersionCronograma({
                    pin_lic_correlativo: parseInt(this.idLicitacion.toString()),
                    in_version: this.versionCronograma + 1,
                  })
                  .subscribe((resp) => {
                    this.fechaCronogramaLic = [];
                    this.getCronograma();
                    this.getVersionCronograma();
                  });
              }
            });
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Registro de historial de cronograma"
            );
          }
        );
    } else {
      this.onUpdateLicitacionFechas().then(async () => {
        if (this.idEtapa === 0) {
          this.onSaveLicitacionFechasOriginales().then(() => {
            this.fechaCronogramaLic = [];
            this.getCronograma();
          });
        } else {
          this.fechaCronogramaLic = [];
          this.getCronograma();
          this.getVersionCronograma();
        }
      });
    }
  }

  calcularFechasEdit(etapaIndex: number, fechaM: string) {
    let fechaSplit = fechaM.split("-");
    let prueba = +fechaSplit[2] + 1;
    let fechaActual = Date.UTC(
      +fechaSplit[0],
      +fechaSplit[1] - 1,
      prueba,
      12,
      0,
      0
    );
    let fechaFinAnterior = new Date(fechaActual);

    for (
      let index = etapaIndex + 1;
      index <= this.cronograma.length - 1;
      index++
    ) {
      const element = this.cronograma[index];

      if (element.ACTIVO === "S") {
        fechaFinAnterior = this.calcularDiasHabiles(fechaFinAnterior);
        this.fInicioTemp[element.ETAPA_CORRELATIVO] =
          moment(fechaFinAnterior).format("YYYY-MM-DD");
        var fechaTerminoTemp = new Date(
          fechaFinAnterior.setDate(
            fechaFinAnterior.getDate() + element.DIAS_ESTIMADO
          )
        );

        if (this.cronograma[etapaIndex].DIAS_ESTIMADO === 0) {
          fechaTerminoTemp = new Date(fechaFinAnterior);
        }

        fechaTerminoTemp = this.calcularDiasHabiles(fechaTerminoTemp);

        this.fTerminoTemp[element.ETAPA_CORRELATIVO] =
          moment(fechaTerminoTemp).format("YYYY-MM-DD");

        this.fechaCronogramaLic.push({
          in_etapa: element.ETAPA_CORRELATIVO,
          in_fecha_ini: this.fInicioTemp[element.ETAPA_CORRELATIVO],
          in_fecha_fin: this.fTerminoTemp[element.ETAPA_CORRELATIVO],
          in_hora: 1,
        });

        fechaFinAnterior = new Date(
          fechaTerminoTemp.setDate(fechaTerminoTemp.getDate() + 1)
        );
      }
    }

    if (this.idEtapa !== 0) {
      this.licitacionService
        .postRegistraHistoricoCronogramaLicitacion({
          in_correlativo_licitacion: parseInt(this.idLicitacion.toString()),
          in_usuario_correlativo: this.userId,
          in_etapa_cambio_historico: parseInt(this.idEtapa.toString()),
        })
        .subscribe(
          (resp) => {
            this.onUpdateLicitacionFechas().then(async () => {
              if (this.idEtapa === 0) {
                this.onSaveLicitacionFechasOriginales().then(() => {
                  this.fechaCronogramaLic = [];
                  this.getCronograma();
                });
              } else {
                this.licitacionService
                  .putActualizaVersionCronograma({
                    pin_lic_correlativo: parseInt(this.idLicitacion.toString()),
                    in_version: this.versionCronograma + 1,
                  })
                  .subscribe((resp) => {
                    this.fechaCronogramaLic = [];
                    this.getCronograma();
                    this.getVersionCronograma();
                  });
              }
            });
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Registro de historial de cronograma"
            );
          }
        );
    } else {
      this.onUpdateLicitacionFechas().then(async () => {
        if (this.idEtapa === 0) {
          this.onSaveLicitacionFechasOriginales().then(() => {
            this.fechaCronogramaLic = [];
            this.getCronograma();
          });
        } else {
          this.fechaCronogramaLic = [];
          this.getCronograma();
          this.getVersionCronograma();
        }
      });
    }
  }

  onCalHrInicio(idEtapa: number, hrs: number) {
    if (hrs < 25 && hrs > 0) {
      this.hInicioTemp[idEtapa] = Number.isNaN(hrs) ? 0 : hrs;
      this.licitacionService
        .getFormatearFecha(
          moment(this.fInicioTemp[idEtapa]).format("YYYY-MM-DD")
        )
        .subscribe((resp) => {
          if (resp) {
            this.fInicioText[idEtapa] =
              JSON.parse(resp)[0].FECHA_PALABRAS + ", " + hrs + ":00 hrs.";
          }
        });
    } else {
      this.hInicioTemp[idEtapa] = 0;
      this.notifyService.showError(
        "Debe ingresar numeros entre el 1 y 24",
        "Calculo de horas"
      );
    }
  }

  onCalHrTermino(idEtapa: number, hrs: number) {
    if (hrs < 25 && hrs > 0) {
      this.hTerminoTemp[idEtapa] = Number.isNaN(hrs) ? 0 : hrs;
      this.hInicioTemp[idEtapa + 1] = Number.isNaN(hrs) ? 0 : hrs;

      this.licitacionService
        .getFormatearFecha(
          moment(this.fTerminoTemp[idEtapa]).format("YYYY-MM-DD")
        )
        .subscribe((resp) => {
          if (resp) {
            this.fInicioText[idEtapa + 1] =
              JSON.parse(resp)[0].FECHA_PALABRAS +
              ", " +
              ("0" + hrs).slice(-2) +
              ":00 hrs.";
            this.fTerminoText[idEtapa] =
              JSON.parse(resp)[0].FECHA_PALABRAS +
              ", " +
              ("0" + hrs).slice(-2) +
              ":00 hrs.";
          }
        });
    } else {
      this.hTerminoTemp[idEtapa] = 0;
      this.notifyService.showError(
        "Debe ingresar numeros entre el 1 y 24",
        "Calculo de horas"
      );
    }

    this.fechaCronogramaLic.push({
      in_etapa: idEtapa,
      in_fecha_ini:
        this.fInicioTemp[idEtapa] +
        "T" +
        ("0" + this.hInicioTemp[idEtapa]).slice(-2) +
        ":00",
      in_fecha_fin:
        this.fTerminoTemp[idEtapa] +
        "T" +
        ("0" + this.hTerminoTemp[idEtapa]).slice(-2) +
        ":00",
      in_hora: 1,
    });
  }
}
